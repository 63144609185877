@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/Hellix-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Hellix';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Hellix-SemiBold.otf') format('opentype');
}

.unsupported-page .container {
  width: 100%;
  height: 100vh;
  color: #ffffff;
  font-family: 'Hellix', sans-serif;
  position: relative;
  background-color: #111111;
  background-image: url('../../../../images/clouds.png');
  background-repeat: no-repeat;
  background-position: 50% 90%;
}

.unsupported-page .navbar {
  max-width: 1920px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px 35px 2px;
  z-index: 5;
}

.unsupported-page .navbar .exit {
  border: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;
  padding: 8px 52px 8px 20px;
  background-image: url('../../../../images/icons/out.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
}

.unsupported-page .navbar .logo {
  width: 174px;
  height: 50px;
  background-image: url('../../../../images/icons/logo-full.svg');
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: -100px;
}

.unsupported-page .content h1 {
  font-size: 48px;
  margin: 0;
}

.unsupported-page .content p {
  margin: 48px 0;
  font-size: 20px;
  line-height: 140%;
  color: #767676;
}

.unsupported-page .content {
  max-width: 720px;
  margin: 0 auto;
  padding: 106px 0 0 0;
  text-align: center;
}

@media (max-width: 768px) {
  .unsupported-page .navbar .logo {
    width: 32px;
    height: 40px;
    background-image: url('../../../../images/icons/logo-icon.svg');
    background-repeat: no-repeat;
    display: block;
    margin: 8px auto 0;
  }

  .unsupported-page .navbar .exit {
    display: none;
  }

  .unsupported-page .content h1 {
    font-size: 32px;
    margin: 0 24px;
  }

  .unsupported-page .content p {
    margin: 24px 24px 36px;
    font-size: 16px;
    line-height: 140%;
    color: #767676;
  }
}
